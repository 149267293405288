<template>
  <div>
    <b-modal
      id="forget-password-modal"
      :title="$t('forgotPasswordTitle')"
      hide-footer
      centered
      @hidden="handleModalClose()"
    >
      <b-overlay
        variant="transparent"
        :show="loading"
        spinner-variant="primary"
        spinner-type="border"
        blur="0.1"
        opacity=".25"
        rounded="sm"
        class="d-flex justify-content-center align-items-center"
      >
        <div class="d-flex flex-column align-items-center">
          <div
            v-if="emailSent"
            style="display: contents"
          >
            <feather-icon
              icon="MailIcon"
              size="4x"
              class="mb-1 text-primary"
            />
            <p class="text-center">
              {{ $t('passRecoveryEmailSent') }}
            </p>
            <b-button
              variant="primary"
              block
              @click="$bvModal.hide('forget-password-modal')"
            >
              {{ $t('dataGeneric.close') }}
            </b-button>
          </div>
          <div v-else>
            <p class="text-center">
              {{ $t('forgotPasswordSub') }}
            </p>
            <b-form @submit.prevent="fetchEmail">
              <b-form-group
                :label="$t('email')"
                class="mb-1"
                label-for="email-input"
              >
                <b-form-input
                  id="email-input"
                  v-model="email"
                  type="email"
                  :placeholder="$t('enterEmail')"
                  required
                />
              </b-form-group>
              <b-button
                class="mb-2"
                type="submit"
                variant="primary"
                block
                :disabled="loading"
              >
                {{ $t('dataGeneric.submit') }}
              </b-button>
            </b-form>
            <b-alert
              v-if="errorMessage"
              variant="danger"
              show
              class="w-100 text-center"
            >
              {{ errorMessage }}
            </b-alert>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'

import {
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BAlert,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import useGraphJwt from '@/auth/jwt/useGraphJwt'
import useJwt from '@/auth/jwt/useJwt'
import { setUserData } from '@/auth/utils'

import {
  messageError,
} from '@/store/functions'

export default {
  name: 'RecoverPasswordModal',
  components: {
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert,
    BModal,
    FeatherIcon,
  },
  props: {
    username: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: '',
      emailSent: false,
      loading: false,
      errorMessage: '',
    }
  },
  mounted() {
  },
  methods: {
    handleModalClose() {
      this.$emit('modal-closed', this.email, this.username)
      this.resetState()
    },
    resetState() {
      this.email = ''
      this.emailSent = false
      this.loading = false
      this.errorMessage = ''
    },
    async fetchEmail() {
      this.loading = true
      useGraphJwt.login(process.env.VUE_APP_RECOVERY_CUSTOM_USER_USERNAME, process.env.VUE_APP_RECOVERY_CUSTOM_USER_PASSWORD).then(response => {
        const { errors, data } = response.data

        if (errors) {
          console.error(errors[0].message)
          this.errorMessage = errors[0].message
          return
        }

        useGraphJwt.setToken(data.tokenAuth.token)
        const userData = { ...data.tokenAuth.user }

        setUserData(userData)
        const query = `mutation{
                      sendPasswordChangeMutation(email:"${this.email}", username:"${this.username}"){
                        result
                        error
                      }
                  }`

        axios
          .post('', {
            query,
          }).then(result => {
            const responseData = result.data.data.sendPasswordChangeMutation

            messageError(result, this)
            if (responseData.result === true) {
              this.emailSent = true
            } else {
              console.error(responseData.error)
              this.errorMessage = this.$t('passRecoveryEmailError')
            }
            this.loading = false
            this.logout()
          }).catch(error => {
            console.error(error)
            this.loading = false
            this.logout()

            this.errorMessage = this.$t('passRecoveryEmailError')
          })
      }).catch(err => {
        this.loading = false
        console.error(err)
      })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      document.cookie.split(';').forEach(cookie => {
        const [name] = cookie.split('=')
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      })
    },
  },
}
</script>

  <style scoped>
  .text-center {
    text-align: center;
  }
  .text-primary {
    color: #007bff;
  }
  </style>
