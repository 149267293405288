<template>
  <div :class="loginLite ? 'auth-wrapper auth-v1 px-2' : 'auth-wrapper auth-v2'">
    <!-- LOGIN INTERACTVTY -->
    <b-row
      v-if="!loginLite"
      class="auth-inner m-0"
    >
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/logo_azul_Plenitas.png')"
          fluid
          style="height: 60px"
          alt="placeholder"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-alert
            v-if="loginErrors"
            variant="warning"
            show
          >
            <h4 class="alert-heading">
              {{
                checkUser(loginErrors[0])
              }}
            </h4>
          </b-alert>
          <b-alert
            v-if="errorConexion"
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              {{ $t('errorCont') }}
            </h4>
          </b-alert>
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('welcome') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('login') }}
          </b-card-text>

          <!-- form -->
          <b-overlay
            variant="transparent"
            :show="show"
          >
            <validation-observer
              ref="loginForm"
              v-slot="{ invalid }"
            >
              <b-form
                id="login"
                class="auth-login-form mt-2"
                @keyup="onKeyUp($event)"
                @submit.prevent="login"
              >
                <!-- email -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('User')"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                    label-for="login-email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      :placeholder="$t('User')"
                      autocomplete="new-password"
                    />
                  </b-form-group>
                </validation-provider>
                <!-- forgot password -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-form-group :state="errors.length > 0 ? false : null">
                    <div class="d-flex justify-content-between">
                      <label for="login-password">{{ $t('administrators.pass') }}</label>
                      <b-link
                        :disabled="userEmail == ''"
                        @click="onForgetPasswordModalOpened()"
                      >
                        <small>{{ $t('forgotPassword') }}</small>
                      </b-link>
                    </div>
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        :placeholder="$t('administrators.pass')"
                        autocomplete="new-password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback>{{ $t('reqPass') }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- submit buttons -->
                <b-button
                  id="send"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('loginLog') }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <!-- LOGIN LITE -->

    <div
      v-else
      class="auth-inner py-2"
    >
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to Vuexy! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('login') }}
        </b-card-text>
        <b-alert
          v-if="loginErrors"
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            {{
              checkUser(loginErrors[0])
            }}
          </h4>
        </b-alert>
        <b-alert
          v-if="errorConexion"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            {{ $t('errorCont') }}
          </h4>
        </b-alert>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          v-slot="{ invalid }"
        >
          <b-form
            id="login"

            class="auth-login-form mt-2"
            @keyup="onKeyUp($event)"
            @submit.prevent="login"
          >
            <!-- email -->
            <validation-provider
              v-slot="{ errors }"
              name="Email"
              vid="email"
              rules="required"
            >
              <b-form-group
                :label="$t('User')"
                :invalid-feedback="$t('required')"
                :state="errors.length > 0 ? false : null"
                label-for="login-email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  :placeholder="$t('User')"
                  autocomplete="new-password"
                />
              </b-form-group>
            </validation-provider>

            <!-- password -->
            <validation-provider
              v-slot="{ errors }"
              name="Password"
              vid="password"
              rules="required"
            >
              <b-form-group
                :label="$t('administrators.pass')"
                label-for="login-password"
                :state="errors.length > 0 ? false : null"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    :placeholder="$t('administrators.pass')"
                    autocomplete="new-password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>{{ $t('reqPass') }}</b-form-invalid-feedback>
                <!-- <div class="d-flex justify-content-end mb-2">
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>{{ $t('forRes') }}</small>
                  </b-link>
                </div> -->
              </b-form-group>
            </validation-provider>

            <!-- submit button -->
            <b-button
              id="send"
              type="submit"
              variant="primary"
              block
              :disabled="invalid"
            >
              {{ $t('loginLog') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>

    <forget-password-modal
      v-if="userEmail !== ''"
      modal-id="forget-password-modal"
      :username="userEmail"
      @modal-closed="onForgetPasswordModalClosed"
    />

    <b-modal
      id="twoFactorAuth"
      ref="twoFactorAuth"
      hide-footer
      size="lg"
      no-close-on-backdrop
      hide-header-close
      centered
      :title="$t('twoFactor.authMode')"
      @hidden="ocultar"
    >
      <div class="d-block text-center">
        <two-factor-authentication
          :factor="tfaSelected"
          :registered="twoFactorRegistered"
          @validate="enterToPlatform"
        />
      </div>
    </b-modal>
    <b-modal
      id="modal-xl"
      ref="platforms-modal"
      hide-footer
      size="lg"
      :title="$t('selectPlatf')"
      @hide="ocultar"
    >
      <div class="d-block text-center">
        <platform-selector
          v-if="isMultiplatformUser"
          :is-superuser="superuser"
          @client="loginMultiplatform"
        />
        <platform-selector
          v-else
          :is-superuser="superuser"
          @client="loginPlatform"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BOverlay,
  VBTooltip,
  BAlert,
  BModal,
} from 'bootstrap-vue'
import useGraphJwt from '@/auth/jwt/useGraphJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { clientNode, getUserData, setUserData } from '@/auth/utils'
import PlatformSelector from '@/views/common/PlatformSelector.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { check2fa, checkPermissions } from '@/store/functions'
import ForgetPasswordModal from '@/views/common/ForgetPasswordModal.vue'
import axios from '@axios'
import TwoFactorAuthentication from './TwoFactorAuthentication.vue'

const loginV2Dark = require('@/assets/images/pages/login-v2-dark.svg')

const loginV2 = require('@/assets/images/pages/login-v2.svg')

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    BCardText,
    BCardTitle,
    TwoFactorAuthentication,
    BFormInvalidFeedback,
    BImg,
    BCard,
    BForm,
    BButton,
    BAlert,
    PlatformSelector,
    ValidationProvider,
    ValidationObserver,
    ForgetPasswordModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      loginAdmin: false,
      userEmail: '',
      sideImg: loginV2,
      loginErrors: '',
      errorConexion: '',
      show: false,
      // validation rules
      required,
      user: null,
      saveTfa: false,
      email,
      rol: '',
      tfaSelected: null,
      twoFactorRegistered: false,
      isMultiplatformUser: false,
      superuser: false,
      loginLite: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = loginV2Dark
      return this.sideImg
      // }
      // return this.sideImg
    },
  },
  mounted() {
    const currentUrl = window.location.host
    this.loginLite = currentUrl === process.env.VUE_APP_LITE_URL
    document.getElementById('login-email').focus()
  },
  methods: {
    enterToPlatform(faSelected) {
      this.isMultiplatformUser = this.user.tokenAuth.user.isMultiplatformUser

      if (this.isMultiplatformUser) {
        useGraphJwt.setToken(this.user.tokenAuth.token)
        const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
        console.log(mode)
        if (mode === 'sandbox') {
          const query = `{
              allClients(name_Icontains:"gamebredbareknuckle") {
                edges {
                  node {
                    ${clientNode()}
                      name
                      order
                  }
                }
              }
            }`
          axios.post('', { query }).then(result => {
            const client = result.data.data.allClients.edges[0].node
            this.loginMultiplatform(client)
          }).catch(() => {

          })
        } else {
          this.$refs['platforms-modal'].show()
        }
        return
      }
      useGraphJwt.getProfile().then(res => {
        const { profile } = res.data.data.me
        const userData = getUserData()
        userData.profile = profile
        this.$ability.update(userData.ability)
        setUserData(userData)
        const currentRoute = this.$route.path
        try {
          this.rol = `${this.$t('Welcome')} ${this.$t(userData.groups.edges[0].node.name)}`
        } catch (error) {
          this.rol = this.$t('logSucc')
        }
        if (this.saveTfa) {
          useGraphJwt.updateTfaSelected(userData.id, faSelected)
        }

        if (currentRoute !== '/dashboard') {
          this.$router.push('dashboard').then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$t('Welcome')} ${userData.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: this.rol,
              },
            })
          }).catch(error => {
            console.log(error)
            this.errorConexion = error
            this.show = false
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    ocultar() {
      if (!this.loginAdmin) {
        this.show = false
      }
    },
    onKeyUp(event) {
      const keycode = event.keyCode || event.which
      if (keycode === 13) {
        if (document.activeElement.id === 'login-email') {
          document.getElementById('login-password').focus()
        }
        if (document.activeElement.id === 'login-password') {
          document.getElementById('send').click()
        }
      }
    },
    checkUser(rules) {
      if (rules.message) {
        return (this.$t('errPass'))
      }
      return ''
    },

    login() {
      this.show = true

      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useGraphJwt.login(this.userEmail, this.password).then(response => {
            const { data, errors } = response.data
            this.user = data
            if (errors) {
              console.log(errors[0].message)

              this.loginErrors = errors
              this.show = false
              return
            }

            useGraphJwt.lastLogin(this.user.tokenAuth.user.id).then(() => {
              if (data.tokenAuth.user?.isSuperuser) {
                useGraphJwt.setToken(this.user.tokenAuth.token)
                this.superuser = true
                this.$refs['platforms-modal'].show()
                return
              }

              this.twoFactorRegistered = this.user.tokenAuth.user.twoFactorRegistered
              this.tfaSelected = this.user.tokenAuth.user.tfaSelected

              /* if (!data.tokenAuth.user?.profile) {
              this.loginErrors = [{ message: 'Final users access denied.' }]
              this.show = false
              return
            } */

              const ability = {
                ability: [
                  {
                    action: 'manage',
                    subject: 'all',
                  },
                ],
              }

              useGraphJwt.setToken(data.tokenAuth.token)
              const userData = { ...data.tokenAuth.user, ...ability }
              setUserData(userData)

              if (!checkPermissions('users.dont_need_2fa')) {
              // Comprobar con método si peta al hacer query, si no peta no pide doble verificación
                check2fa().then(result => {
                  if (result) {
                    this.saveTfa = true
                    this.$refs.twoFactorAuth.show()
                  } else {
                    this.enterToPlatform()
                  }
                }).catch(err => {
                  console.log(err)
                })
              } else {
                this.enterToPlatform()
              }
            }).catch(err => {
              console.log(err)
            })
          }).catch(error => {
            console.log((error))
            this.errorConexion = error
            this.show = false
          })
        }
      })
    },
    loginPlatform(data) {
      this.loginAdmin = true
      this.$refs['platforms-modal'].hide()
      const ability = {
        ability: [
          {
            action: 'manage',
            subject: 'all',
          },
        ],
      }

      this.user.tokenAuth.user.profile = { client: data }
      this.user.tokenAuth.user.groups.edges = [{
        node: {
          name: 'Administrador',
        },
      }]

      const userData = { ...this.user.tokenAuth.user, ...ability }
      setUserData(userData)

      this.$ability.update(userData.ability)
      const currentRoute = this.$route.path

      if (currentRoute !== '/dashboard') {
        this.$router.push('dashboard').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.$t('Welcome')} ${userData.username}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Inicio de sesión como SUPERADMIN',
            },
          })
        }).catch(error => {
          console.log(error)
          this.errorConexion = error
          this.show = false
        })
      }
    },
    loginMultiplatform(data) {
      const { id } = data
      this.loginAdmin = true
      useGraphJwt.setPlatform(id).then(() => {
        this.$refs['platforms-modal'].hide()
        const ability = {
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
        }

        this.user.tokenAuth.user.profile = { client: data }

        const userData = { ...this.user.tokenAuth.user, ...ability }
        setUserData(userData)

        this.$ability.update(userData.ability)
        const currentRoute = this.$route.path

        if (currentRoute !== '/dashboard') {
          this.$router.push('dashboard').then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$t('Welcome')} ${userData.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Inicio de sesión como usuario multiplataforma',
              },
            })
          }).catch(error => {
            console.log(error)
            this.errorConexion = error
            this.show = false
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onForgetPasswordModalClosed() {
      console.log('Password recovery modal closed.')
    },
    async onForgetPasswordModalOpened() {
      this.$bvModal.show('forget-password-modal')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
